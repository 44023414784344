import { UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { Api } from './api';
import {
  IAlert,
  IAlertGetAlertCyclesRequest,
  IAlertGetAlertItemsRequest,
  IAlertGetAlertItemsResponse,
  IAlertGetAlertRequest,
  IAlertGetAlertsCyclesResponse,
  IAlertGetAlertsRequest,
  IAlertGetAlertsResponse,
  IAlertGetAllAlertsRequest,
  IAlertGetAllAlertsResponse,
  IAlertUpdateAlertItemRequest,
  IAlertUpdateAlertMonitoringRequest,
  IAlertUpdateAlertRequest,
  IAlertUpdateAlertsRequest,
  IUpdateAlerts,
  IUpdateAlertsResponse,
} from './dtos/alert';
import { IStatusResponse } from './dtos/common';
import { getApiBaseUrl, getTypeApi } from '../utils/helpers/apiHelpers';
import { getNextPageParam } from '../utils/helpers/helperFunctions';

export class AlertApi extends Api {
  readonly baseUrl: string = '/resolutioncenter/api/v4';

  readonly getAlerts = (params: IAlertGetAlertsRequest) => {
    const { offset = 0, type, status, id, filters, is_stale = false, entity_type } = params;

    return this.http.get<IAlertGetAlertsResponse>(this.route(`${getTypeApi(type)}/${id}/alerts/`), {
      params: { offset, status, entity_type, is_stale, ...filters },
    });
  };

  readonly getAlert = (params: IAlertGetAlertRequest) => {
    const { type, id, alertId } = params;
    return this.http.get<IAlert>(this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/`));
  };

  readonly getAlertItems = (params: IAlertGetAlertItemsRequest) => {
    const { offset = 0, status, type, id, alertId, start_time, end_time } = params;

    return this.http.get<IAlertGetAlertItemsResponse>(
      this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/items/`),
      {
        params: { offset, status, start_time, end_time },
      }
    );
  };

  readonly updateAlert = (params: IAlertUpdateAlertRequest) => {
    const { type, id, status, alertId, comment, context } = params;

    return this.http.put(this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/`), {
      status,
      ...(comment && { comment: { body: comment, context } }),
    });
  };

  readonly updateMultipleAlerts = (params: IUpdateAlerts) => {
    const { ids, filters, comment, is_stale, status, q } = params;

    return this.http.put<IUpdateAlertsResponse>(
      this.route(`/alerts/update-alerts/?ids=${ids.join(',')}`),
      {
        status: 1,
        ...(comment && { comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } } }),
      },
      {
        params: {
          ...filters,
          is_stale,
          status,
          q,
        },
      }
    );
  };

  readonly updateAlertItem = (params: IAlertUpdateAlertItemRequest) => {
    const { type, id, status, alertId, itemId, comment, context } = params;

    return this.http.put(this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/items/${itemId}/`), {
      status,
      ...(comment && { comment: { body: comment, context } }),
    });
  };

  readonly updateAlertMonitoring = (params: IAlertUpdateAlertMonitoringRequest) => {
    const { type, id, days = null, isMuted = true, alertId } = params;

    return this.http.put(this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/monitoring/`), {
      days,
      is_muted: isMuted,
    });
  };

  readonly getAlertCycles = (params: IAlertGetAlertCyclesRequest) => {
    const { type, id, alertId } = params;

    return this.http.get<IAlertGetAlertsCyclesResponse>(
      this.route(`${getTypeApi(type)}/${id}/alerts/${alertId}/cycles/`)
    );
  };

  readonly updateAlerts = (params: IAlertUpdateAlertsRequest) => {
    const { type, ids, status, comment, context } = params;

    return this.http.put<IStatusResponse>(
      `${getApiBaseUrl(type)}/update-alerts/`,
      {
        status,
        ...(comment && { comment: { body: comment, context } }),
      },
      {
        params: { ids },
      }
    );
  };

  readonly getAllAlerts = (params: IAlertGetAllAlertsRequest) => {
    return this.http.get<IAlertGetAllAlertsResponse>(this.route('alerts/'), {
      params,
    });
  };
}

export const alertApi = new AlertApi();

export const useAlertGetAlerts = (params: IAlertGetAlertsRequest, options?: UseQueryOptions) => {
  const key = ['alertApi.getAlerts', params.id, params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => alertApi.getAlerts({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useAlertGetAlert = (params: IAlertGetAlertRequest, options?: UseQueryOptions) => {
  const key = ['alertApi.getAlert', params.id, params.alertId, params.type];
  const result = useQuery(key, () => alertApi.getAlert(params), options as unknown);
  return result;
};

export const useAlertGetAlertItems = (params: IAlertGetAlertItemsRequest, options?: UseQueryOptions) => {
  const key = ['alertApi.getAlertItems', params.id, params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => alertApi.getAlertItems({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useAlertGetAlertCycles = (params: IAlertGetAlertCyclesRequest, options?: UseQueryOptions) => {
  const key = ['alertApi.getAlertsHistory', params.id, params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => alertApi.getAlertCycles({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useAlertGetAllAlerts = (params: IAlertGetAllAlertsRequest, options?: UseQueryOptions) => {
  const key = ['alertApi.getAllAlerts', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => alertApi.getAllAlerts({ ...params, rule_name: undefined, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};
